<template>
  <div class="box col-wrapper">
    <el-form label-width="120px">
      <el-form-item label="旧密码">
        <el-input type="password" v-model="form.oldPassword"></el-input>
      </el-form-item>
      <el-form-item label="新密码">
        <el-input type="password" v-model="form.password"></el-input>
      </el-form-item>
      <el-form-item label="确认新密码">
        <el-input type="password" v-model="form.passwordRepet"></el-input>
      </el-form-item>
    </el-form>
    <Button @click="changePassword" type="primary">修改密码</Button>
  </div>
</template>

<script>
import * as accountApi from "../../api/api/Account"

export default {
  name: "PasswordSetting",
  data() {
    return {
      form: {
        password: "",
        oldPassword: "",
        passwordRepet: ""
      }
    }
  },
  methods: {
    /**
     * 修改密码
     */
    changePassword() {
      if (this.form.password !== this.form.passwordRepet) {
        this.$message.error("两次新密码输入不一致！")
        return
      }
      accountApi.UpdatePassword(this.$qs.stringify(this.form))
          .then(res => {
            if (res.data.code === 200) {
              this.$message.success(res.data.message)
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            console.error(err)
            this.$message.error("修改密码失败")
          })
    }
  }
}
</script>

<style scoped>

</style>