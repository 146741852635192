import request from '../axios'

/**
 * 获取基地
 */
export function Login(data) {
  return request({
    url: 'rest/account/Login',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}


/**
 * 获取基地
 */
export function UpdatePassword(data) {
  return request({
    url: 'rest/account/UpdatePassword',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}